import React from 'react';

const Game2 = () => {
    return (
        <div>
            <h3>Collect the correct gifts játék</h3>
            <iframe src="collectgifts/index.html" width='100%' height='450' frameBorder="0" className={'my-5 md:hidden'}/>
            <iframe src="collectgifts/index.html" width='100%' height='600' frameBorder="0" className={'my-5 hidden md:block'}/>
        </div>
    );
};

export default Game2;