//import React from "@types/react";
import React from "react";
import Game2 from "../../Components/Game/Game2";

export const Days = [{
    id: 1, content:
        <>
            <h2>Karácsonyi menüajánló</h2>
            <p className={'text-brandDark lg:text-base'}>Nézzétek meg karácsonyi menüajánlókat: a 6 részből álló sorozat egy-egy epizódjában felsővezetőink ajánlják számotokra kedvenc karácsonyi fogásaikat. Elsőként egy izgalmas tatár beefsteak receptet osztunk meg veletek Szombat Tamás közreműködésével. Az ételek elkészítéséért köszönet Nyíri Szásának, az Arany Kaviár Étterem chef-tulajdonosának. A receptet és az ételhez illő bor ajánlót megtaláljátok az intraneten / Alfa+-ban.</p>
            <div className={'hidden 2xl:flex justify-center my-4'}>
                <video width="560" controls>
                    <source src="assets/1-menuajanlo-tatar.mp4"/>
                </video>
            </div>
            <div className={'md:flex 2xl:hidden justify-center my-4 hidden'}>
                <video width="500" controls>
                    <source src="assets/1-menuajanlo-tatar.mp4"/>
                </video>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <video width="100%" height="250" controls>
                    <source src="assets/1-menuajanlo-tatar.mp4"/>
                </video>
            </div>
        </>
},
    {
        id: 2, content:
            <>
                <h2>Ajánló a kollégáktól</h2>
                <img src="assets/noel-naploja.jpg" alt="Noel naplója" className={"hidden md:block my-4 lg:max-w-[580px]"}/>
                <img src="assets/noel-naploja-mob.jpg" alt="Noel naplója" className={"md:hidden my-4"}/>
            </>
    },
    {
        id: 3, content:
            <>
                <h2>Ajánló a kollégáktól</h2>
                <a href="https://mobeecards.store/" target={"_blank"}><img src="assets/kartyajatek.jpg" alt="Kártyajáték" className={"hidden md:block my-4 lg:max-w-[580px]"}/></a>
                <a href="https://mobeecards.store/" target={"_blank"}><img src="assets/kartyajatek-mob.jpg" alt="Kártyajáték" className={"md:hidden my-4"}/></a>
            </>
    },
    {
        id: 4, content:
            <>
                <h2>Karácsonyi menüajánló</h2>
                <p className={'text-brandDark lg:text-base'}>A sorozatunk következő részében Kerepesi Károly és Szása sütőtök-krémlevest készít. A receptet és az ételhez illő bor-ajánlót megtaláljátok az intraneten / Alfa+-ban.</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/4nap-menuajanlo.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/4nap-menuajanlo.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 5, content: <>
            <h2>Ajánló a kollégáktól</h2>
            <img src="assets/a-fuvesasszony.jpg" alt="A füvesasszony" className={"hidden md:block my-4 max-w-[575px] 2xl:max-w-full"}/>
            <img src="assets/a-fuvesasszony-mob.jpg" alt="A füvesasszony" className={"md:hidden my-4"}/>
        </>
    },
    {
        id: 6, content: <>
            <Game2 />
        </>
    },
    {
        id: 7, content: <>
        <h2>Ajánló a kollégáktól</h2>
            <img src="assets/garden-of-lights.jpg" alt="Programajánló: Garden of lights kiállítás" className={"hidden md:block my-4 md:max-w-[575px] 2xl:max-w-full"}/>
            <img src="assets/garden-of-lights-mob.jpg" alt="Programajánló: Garden of lights kiállítás" className={"md:hidden my-4"}/>
        </>
    },
    {
        id: 8, content: <>
            <h2>Karácsonyi menüajánló</h2>
            <p className={'text-brandDark lg:text-base'}>A karácsonyi vacsorák közkedvelt fogása a halászlé. Ahány ház, annyi szokás - ebben a tekintetben is. Most Szása édesapjának kedvenc receptje alapján készíti el Bodor Péter. A receptet és az ételhez illő bor-ajánlót megtaláljátok az intraneten / Alfa+-ban.
            </p>
            <div className={'md:flex justify-center my-4 hidden'}>
                <video width="560" height="315" controls>
                    <source src="assets/8-menuajanlo.mp4"/>
                </video>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <video width="100%" height="250" controls>
                    <source src="assets/8-menuajanlo.mp4"/>
                </video>
            </div>
        </>
    },
    {
        id: 9, content:
            <>
                <h2>Hangolódj az ünnepekre!</h2>
                <p className={'text-brandDark lg:text-base'}>Hallgassátok meg Magyarfi Krisztina, Junior IT Infrastruktúra projektvezető kedvenc karácsonyi dalát. A videót is érdemes megnézni a szép animációért:<br />Nat King Cole - The Christmas Song:</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/wKhRnZZ0cJI"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    },
    {
        id: 10, content: <>
            <h2>Próbáltad már a 8D zenét? Itt az idő, használd a fülhallgatód.</h2>
            <div className={'md:flex justify-center my-4 hidden'}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/4y7GSbyJu6E"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
        </>
    },
    {
        id: 11,
        content: <>
            <h2>Ajánló a kollégáktól</h2>
            <a href="https://hvgkonyvek.hu/konyv/a-nike-sztori-a-legendas-marka-alapitojanak-oneletrajza" target={'_blank'}><img src="assets/11nap.jpg" alt="A Nike story" className={"hidden md:block my-4 md:max-w-[575px] 2xl:max-w-full"}/></a>
            <a href="https://hvgkonyvek.hu/konyv/a-nike-sztori-a-legendas-marka-alapitojanak-oneletrajza" target={'_blank'}><img src="assets/11nap-mob.jpg" alt="A Nike story" className={"md:hidden my-4"}/></a>
        </>
    },
    {
        id: 12,
        content: <>
            <h2>Karácsonyi menüajánló</h2>
            <p className={'text-brandDark lg:text-base'}>Töltött káposzta - újragondolva A kisfilmben Kovács Zsolt meséli el, ő hogyan szokta elkészíteni - malacfejjel a kollagénes hatásért. A receptet és az ételhez illő bor-ajánlót megtaláljátok az intraneten / Alfa+-ban.</p>
            <div className={'md:flex justify-center my-4 hidden'}>
                <video width="560" height="315" controls>
                    <source src="assets/12-menuajanlo.mp4"/>
                </video>
            </div>
            <div className={'flex justify-center my-4 md:hidden'}>
                <video width="100%" height="250" controls>
                    <source src="assets/12-menuajanlo.mp4"/>
                </video>
            </div>
        </>
    },
    {
        id: 13, content:
            <>
                <h2>Ajánló a kollégáktól</h2>
                <p className={'text-brandDark lg:text-base'}>
                    Podcast-rajongóknak ajánljuk: előfizetés a Spotify-ra, Az élet meg minden vagy az Alinda podcastra.
                    Nemcsak ünnepi kikapcsolódáshoz ideális, hanem szuper karácsonyi ajándék is lehet. Utóbbi esetben is be lehet állítani a saját bankkártyánkra. Költség: havi 2.000 – 3.000 Ft.
                </p>
                <p className={'text-brandDark lg:text-base'}>A PR csapat ajánlásával</p>
                <div className={'flex flex-col md:flex-row gap-8 justify-center my-10'}>
                    <div className={'flex flex-col items-center gap-4'}>
                        <img src="assets/az-elet-meg-minden.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://open.spotify.com/show/7cNwO6NYlhG9FxpFZqhMdH?si=cb0256ce832c4f44" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Meghallgatom</a>
                    </div>
                    <div className={'flex flex-col items-center gap-4'}>
                        <img src="assets/alinda-podcast.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://open.spotify.com/show/4q0CJej0JcZVhK2Weesq8q?si=fb7010964d614136" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Meghallgatom</a>
                    </div>
                </div>

            </>
    },
    {
        id: 14, content:
            <>
                <h2>Ajánló a kollégáktól</h2>
                <p className={'text-brandDark lg:text-base'}>
                    "Szerintem a mi szakmánk nem a megszokott "8 órás szakma", nekünk az élet minden területén jól jönnek azok a hasznos tapasztalatok, tippek, amiket sokszor könyvekben olvasunk, mindössze alkalmaznunk kell a tanultakat. Mindenki fut, rohan, ezer és ezer dolga van, viszont, a decemberi készülődésben, illetve az ünnepek alatt mindig akad egy-egy óra, amikor magunk vagyunk.
                    Én ezeket szeretem arra használni, hogy olvassak, és ha már olvasok, akkor legyen használható a tudás, amit kapok."
                </p>
                <p className={'text-brandDark lg:text-base'}>
                Köteles Ildi, Manager a következő hasznos olvasmányokat javasolja, köszönjük az ajánlást!:
                </p>
                <div className={'flex flex-col md:flex-row gap-8 justify-center mb-10'}>
                    <div className={'flex flex-col justify-end items-center gap-4'}>
                        <img src="assets/olah-tamas-a-kifogaskezeles-mestere.png" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://kifogaskezeles.hu/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Elolvasom</a>
                    </div>
                    <div className={'flex flex-col justify-end items-center gap-4'}>
                        <img src="assets/az-ertekesites-nem-szerencsejatek.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://comline.hu/webshop/konyvek/nagy-bela-az-ertekesites-nem-szerencsejatek/" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Elolvasom</a>
                    </div>
                    <div className={'flex flex-col justify-end items-center gap-4'}>
                        <img src="assets/az-ertekesites-titkos-kodja.jpg" alt="" className={'rounded-lg max-w-[200px]'}/>
                        <a href="https://www.google.hu/books/edition/Az_%C3%A9rt%C3%A9kes%C3%ADt%C3%A9s_titkos_k%C3%B3dja/237gDwAAQBAJ?hl=hu&gbpv=1&dq=%C3%BCzletk%C3%B6t%C5%91knek+k%C3%B6nyv&printsec=frontcover" target="_blank"
                           className="btn-primary w-fit px-5 px-2">Elolvasom</a>
                    </div>
                </div>
            </>
    },
    {
        id: 15, content:
            <>
                <h2>Karácsonyi menüajánló</h2>
                <p className={'text-brandDark lg:text-base'}>Narancs, dió, méz - az adventi időszak elmaradhatatlan hozzávalói. Ezekből készít finom palacsintát Helli József és Szása. A receptet és az ételhez illő bor-ajánlót megtaláljátok az intraneten / Alfa+-ban.</p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/palacsinta.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/palacsinta.mp4"/>
                    </video>
                </div>
            </>
    },
    {
        id: 16, content:
            <>
                <h2>5 magyar kézműves akiket érdemes követni, nem csak az ünnepek alatt.</h2>
                <div className="mt-6 mb-4">
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
                        <div><h4 className="mb-2">1. Milyenkedves</h4><p>Természetközeli minták, azoknak akik szeretik
                            az erdőt, a növényeket, állatokat, madarakat. </p></div>
                        <div className="flex flex-col items-end"><a href="https://milyenkedves.com/" target="_blank"
                                                                    className="btn-primary w-fit px-5 px-2">Megnézem</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
                        <div><h4 className="mb-2">2. Vatesprojekt</h4><p>A magyar irodalom, költészet és mesék
                            szerelmeseinek.</p></div>
                        <div className="flex flex-col items-end"><a href="https://www.instagram.com/vatesproject/" target="_blank"
                                                                    className="btn-primary w-fit px-5 px-2">Megnézem</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
                        <div><h4 className="mb-2">3. Susu keramika</h4><p>#playclay #Flora&amp;Szaffi #makers #slow
                            designers #based in Budapest</p></div>
                        <div className="flex flex-col items-end"><a href="https://www.instagram.com/susu_keramika/"
                                                                    target="_blank"
                                                                    className="btn-primary w-fit px-5 px-2">Megnézem</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
                        <div><h4 className="mb-2">4. Babkamanufaktúra</h4><p>A kézzel festett kerámiák bűvöletében.</p>
                        </div>
                        <div className="flex flex-col items-end"><a href="https://www.babkamanufaktura.hu/"
                                                                    target="_blank"
                                                                    className="btn-primary w-fit px-5 px-2">Megnézem</a>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center justify-between">
                        <div><h4 className="mb-2">5. Nagyimutatta</h4><p>„Mi elkészítjük az egyik legcoolabb kézzel
                            hímzett pólódat, ruhádat“</p></div>
                        <div className="flex flex-col justify-center items-end"><a href="https://nagyimutatta.hu/"
                                                                                   target="_blank"
                                                                                   className="btn-primary w-fit px-5 px-2">Megnézem</a>
                        </div>
                    </div>
                </div>
            </>
    },
    {
        id: 17, content:
            <>
                <h2>Hangolódj az ünnepekre!</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/6bbuBubZ1yE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/6bbuBubZ1yE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <p className={'text-brandDark lg:text-base'}>Fogadd szeretettel ezt a videót, és éld át az Ünnepek hangulatát.</p>
            </>
    }
    ,
    {
        id: 18, content:
            <>
                <h2>Ajánló a kollégáktól</h2>
                <a href="https://port.hu/adatlap/film/mozi/szeansz-velenceben-a-haunting-in-venice/movie-247452" target={"_blank"}><img src="assets/krimik.jpg" alt="Karácsonyi krimik" className={"hidden md:block my-4 md:max-w-[575px] 2xl:max-w-full"}/></a>
                <a href="https://port.hu/adatlap/film/mozi/szeansz-velenceben-a-haunting-in-venice/movie-247452" target={"_blank"}><img src="assets/krimik-mob.jpg" alt="Karácsonyi krimik" className={"md:hidden my-4"}/></a>
            </>
    }
    ,
    {
        id: 19, content:
            <>
                <h2 className={'mb-6'}>Karácsonyi ajándék csomagolás ötletek – 11 remek tipp kreatív és egyedi
                    ajándékcsomagoláshoz</h2>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 py-6 border-y-2 '}>
                    <div className={'order-1'}><img src="assets/csomagolas-1.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/></div>
                    <p className={'order-2 text-lg'}>A karácsonyi mintájú csomagolóanyagok között találhatunk szépeket,
                        de ha
                        valami eredetibbre
                        vágyunk, használhatunk teljesen egyszerű, sima csomagolópapírt, kössünk rá szalagot és díszítsük
                        fehér festékkel, levelekkel, ágakkal.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="assets/csomagolas-2.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Utánozhatjuk egy postai küldemény megjelenését egy
                        egyszerűbb csomagolópapírral, nagy postabélyegekkel, postabélyegzővel, színes zsinórral átkötve
                        – címezzük meg és írjuk alá, hogy teljes legyen a kép.</p>
                </div>

                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="assets/csomagolas-3.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>A gyerekeknek biztosan örömet szerez majd, ha a
                        klasszikus módszerrel, ajándék tartó zokniba tesszük a kisebb meglepetéseket, ezeket horgokra
                        akasztva lógathatjuk is sorban a fa mellett.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="assets/csomagolas-4.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>A kis ajándéktárgyak és édességek nagyszerűen néznek ki
                        szép üvegedényekben, díszítsük madzaggal és kézműves hatású üdvözlőkártyával – az üveg az
                        ajándékozás után is megmarad, emlékeztetőül a szép pillanatokra.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="assets/csomagolas-5.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Tegyük az ajándékokat előre elkészített vagy házi készítésű
                        dobozokba (utóbbi esetben keményebb kartonból mi is elkészíthetjük a dobozt egy az internetről
                        letöltött sablon alapján). A megvásárolható dobozok között biztosan találunk szépet, de egyedibb
                        lesz az ajándék, ha magunk csomagoljuk egy szép, skandináv mintájú papírba.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="assets/csomagolas-6.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Ha a doboz helyett valami érdekesebb formát szeretnénk,
                        az interneten találhatunk sablonokat tasakokhoz is, vastagabb, szép mintával díszített papírból
                        hajthatjuk össze.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 '}><img src="assets/csomagolas-7.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Engedjük szabadon a fantáziánkat, hiszen az ajándékokat nem
                        kötelező az unalomig ismert karácsonyi csomagolópapírokba burkolni. Használhatunk erre a célra
                        pl. térképet (utazók nagy örömére), de egy újságpapírból is remek dolgokat hozhatunk ki.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="assets/csomagolas-8.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Maradt valamennyi tapéta a lakásdekorációból?
                        Felhasználhatjuk azt is, de ha ügyesen bánunk a textilekkel, akkor egy maradék anyagból is
                        készíthetünk egyedi csomagdíszt.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1'}><img src="assets/csomagolas-9.jpg"
                                                    alt=""
                                                    className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Ha valaki szeretne eltérni a szokványos piros, zöld,
                        télapós, fenyőfás, angyalkás csomagolástól, válasszon elegáns fekete-fehér csomagolópapírokat,
                        kreatív díszítéssel. A fűszerek, bogyók, tobozok szépen passzolnak a fekete-fehér
                        csomagoláshoz.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 pb-6'}>
                    <div className={'order-1 md:order-2'}><img src="assets/csomagolas-10.jpg"
                                                               alt=""
                                                               className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 md:order-1 text-lg'}>Bármelyik csomagolást is választjuk, egy kis extra
                        díszítés nem árthat. Legyünk kreatívak, növényekkel, levelekkel, fenyőágakkal.</p>
                </div>
                <div className={'grid md:grid-cols-2 mb-6 gap-5 border-b-2 py-6'}>
                    <div className={'order-1 '}><img src="assets/csomagolas-11.jpg"
                                                     alt=""
                                                     className={'h-auto max-w-[50%] mx-auto rounded'}/>
                    </div>
                    <p className={'order-2 text-lg'}>Egy szép ezüst, fehér, bézs papírt nagyszerűen feldobhatunk kisebb
                        karácsonyi díszekkel, papír csillagokkal, szalagokkal, csipkékkel, textíliákkal és apró
                        figurákkal.</p>
                </div>
                <p><strong>Forrás:</strong> <a href="https://lakbermagazin.hu/praktikus-lakberendezesi-otletek/2777-karacsonyi-ajandek-csomagolas-otletek-11-remek-tipp-kreativ-es-egyedi-ajandekcsomagolashoz.html" target={"_blank"}>https://lakbermagazin.hu/praktikus-lakberendezesi-otletek/2777-karacsonyi-ajandek-csomagolas-otletek-11-remek-tipp-kreativ-es-egyedi-ajandekcsomagolashoz.html</a></p>
            </>
    }
    ,
    {
        id: 20, content:
            <>
                <h2>Karácsonyi menüajánló</h2>
                <p className={'text-brandDark lg:text-base'}>Adventi főzős-sorozatunk bónusz epizódjában Zatykó Péter ünnepi pizzát készít, a hagyományos családi receptjük alapján. A receptet és az ételhez illő bor-ajánlót megtaláljátok az intraneten / Alfa+-ban.
                </p>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/pizza.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/pizza.mp4"/>
                    </video>
                </div>
            </>
    }
    ,
    {
        id: 21, content:
            <>
                <h2>Ajánló a kollégáktól
                </h2>
                <p className={'text-brandDark lg:text-base'}>Do It Yourself karácsonyfa készítés - Lipták Mónika egy kézműves foglalkozást ajánl a fenntarthatóság jegyében, amelyet személyesen is kipróbált már, ennek eredményét láthatjátok a fotón.
                </p><p className={'text-brandDark lg:text-base'}>Lipták Mónika, Életbiztosítási Üzletág vezető ajánlásával</p>

                <div className={'md:flex justify-center my-4 hidden'}>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3o-HfEKmo94"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/3o-HfEKmo94"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen/>
                </div>
            </>
    }
    ,
    {
        id: 22, content:
            <>
                <h2>Fogadjátok szeretettel Grecsó Krisztián karácsonyi üdvözletét</h2>
                <div className={'md:flex justify-center my-4 hidden'}>
                    <video width="560" height="315" controls>
                        <source src="assets/Alfa_advent_22_video_Grecso.mp4"/>
                    </video>
                </div>
                <div className={'flex justify-center my-4 md:hidden'}>
                    <video width="100%" height="250" controls>
                        <source src="assets/Alfa_advent_22_video_Grecso.mp4"/>
                    </video>
                </div>
            </>
    }
    ,
    {
        id: 23, content:
            <>
                <h2>Karácsonyi szakácskönyv</h2>
                <div className="flex flex-col items-center my-8">
                    <a href="assets/karacsonyi-receptek.pdf" target="_blank">
                        <img src="assets/karacsonyi-szakacskonyv.jpg" alt="" className={"mb-8 w-full lg:w-[560px]"}/>
                    </a>

                    <a href="assets/karacsonyi-receptek.pdf" target="_blank"
                       className="btn-primary w-fit px-5 px-2">Megnézem</a>
                </div>
            </>
    }
    ,
    {
        id: 24, content:
            <>
                <h2 className={"mb-4 lg:mb-8"}>Kedves Kollégák!</h2>
                <div className={"lg:flex lg:flex-row lg:items-end lg:mb-4"}>
                    <div className={"basis-[55%] pr-4"}>
                        <p className={'text-brandDark lg:text-base'}>Ismét eltelt egy mozgalmas év, és örömmel jelenthetem ki, hogy az év vége különösen jól alakult. Bizonyára hallottátok, hogy novemberben 55 százalékról 90 százalékra növeltük a részesedésünket a magyar üzletágban, és a napokban írtunk alá egy stratégiai együttműködési megállapodást a magyar kormánnyal.</p>
                        <p className={'text-brandDark lg:text-base'}>Mindezek előkészítése, és a hozzám tartozó területek, országok szokásos évzáró eseményei megsokszorozták a munkámat, de nálam is lassan eljön az ideje a karácsonyi előkészületeknek. Pár napra én is el tudok vonulni a családdal, barátokkal ünnepelni, pihenni. </p>
                        <p className={'text-brandDark lg:text-base mb-0'}>Hallottam, hogy a vezetőitek megosztották veletek kedvenc karácsonyi receptjeiket.  Az én személyes kedvencem, ami sosem maradhat el a karácsonyi ünnepi asztalról, az a halászlé.</p>
                    </div>
                    <div className={"basis-2/5 hidden lg:block"}>
                        <img src="assets/LehelGabor_badge-12-20.jpg" alt="" />
                    </div>
                </div>
                <p className={'text-brandDark lg:text-base'}>Itt Ausztriában hagyományosan sült pontyot, sacher tortát és marcipános kalácsot fogyasztanak karácsonykor. Ebben is sok közös vonás van a magyar és az osztrák szokások között.  </p>
                <p className={'text-brandDark lg:text-base'}>Jó érzéssel tölt el, hogy a VIG, Magyarország piacvezető biztosító csoportjaként, az osztrák-magyar tradíciókra építve alakítja, formálja a szakmánk jövőjét! Ebben megkérdőjelezhetetlen szerepetek van Nektek, Alfásoknak! Dolgos és sikeres év áll mögöttetek! Ideje lassan megpihenni, mérleget vonni és valamelyest feltöltődni!</p>
                <p className={'text-brandDark lg:text-base'}>Ezúton kívánok Nektek kellemes ünnepeket és boldog új évet!</p>
                <img src="assets/LehelGabor_badge-12-20.jpg" alt="" className={"lg:hidden mb-4"} />
                <p className={'text-brandDark lg:text-base'}>
                    Üdvözlettel, <br/>
                    Lehel Gábor <br/>
                    a VIG Igazgatóság tagja <br/>
                </p>
            </>
    }
    ,
]
